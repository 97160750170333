import React from 'react';
import styled from 'styled-components';
import Header from './components/Layouts/Header';
import Sidebar from './components/Layouts/Sidebar';

const StyledLayout = styled.div``;
const StyledContent = styled.div`
  padding-top: 48px;
  display: flex;
`;

export const App: React.FunctionComponent = () => {
  return (
    <StyledLayout>
      <Header />
      <StyledContent>
        <Sidebar />
        <div>Dashboard</div>
      </StyledContent>
    </StyledLayout>
  );
};
