import React from 'react';
import {
  CommandBar,
  Persona,
  IPersonaSharedProps,
  PersonaSize,
  PersonaPresence,
  ContextualMenuItemType,
  DefaultButton,
  SearchBox,
} from 'office-ui-fabric-react';
import { TestImages } from '@uifabric/example-data';
import styled from 'styled-components';

const StyledContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${props => props.theme.palette.themePrimary};
  position: fixed;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
  }
  .ms-CommandBar {
    background: ${props => props.theme.palette.themePrimary};
    height: 48px;
    padding: 0;
    .ms-Button--commandBar {
      background: transparent;
      color: #fff;
      min-width: 48px;
      .ms-Button-icon {
        color: #fff;
      }
      .ms-Button-menuIcon {
        color: #fff;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .ms-SearchBox {
    border: none;
    width: 260px;
  }
  .ms-Persona-Wrapper {
    background: transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    height: 48px;
    border: none;
    i {
      color: #fff;
      margin-top: 1px;
    }
    .ms-Persona {
      height: 48px;
      cursor: pointer;
      .ms-TooltipHost {
        color: #fff;
      }
    }
  }
`;

const StyledBrand = styled.div`
  width: 220px;
  display: flex;
  align-items: center;
  span {
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 1;
    vertical-align: middle;
    outline-offset: -1px;
  }
  .logo {
    background: rgba(255, 255, 255, 0.2);
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
`;

export default () => {
  const getItems = () => {
    return [
      {
        key: 'newItem',
        iconProps: {
          iconName: 'Add',
        },
      },
      {
        key: 'upload',
        iconProps: {
          iconName: 'Upload',
        },
      },
      {
        key: 'share',
        iconProps: {
          iconName: 'Share',
        },
      },
      {
        key: 'download',
        iconProps: {
          iconName: 'Download',
        },
      },
    ];
  };

  const examplePersona: IPersonaSharedProps = {
    imageUrl: TestImages.personaMale,
    imageInitials: 'AR',
    text: 'Annie Reid',
    secondaryText: 'Designer',
    tertiaryText: 'In a meeting',
    optionalText: 'Available at 4:00pm',
  };

  return (
    <StyledContainer>
      <div className={'left'}>
        <StyledBrand>
          <div className="logo" />
          <span>MatrixFace</span>
        </StyledBrand>
        <SearchBox placeholder="Search" onSearch={() => null} />
      </div>
      <div className={'right'}>
        <CommandBar items={getItems()} style={{ backgroundColor: 'transparent' }} />
        <DefaultButton
          className={'ms-Persona-Wrapper'}
          // @ts-ignore
          menuProps={{
            items: [
              {
                key: 'newItem',
                text: 'New',
                onClick: () => console.log('New clicked'),
              },
              {
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider,
              },
              {
                key: 'rename',
                text: 'Rename',
                onClick: () => console.log('Rename clicked'),
              },
              {
                key: 'edit',
                text: 'Edit',
                onClick: () => console.log('Edit clicked'),
              },
              {
                key: 'properties',
                text: 'Properties',
                onClick: () => console.log('Properties clicked'),
              },
              {
                key: 'linkNoTarget',
                text: 'Link same window',
                href: 'http://bing.com',
              },
            ],
          }}
        >
          <Persona {...examplePersona} size={PersonaSize.size32} presence={PersonaPresence.online} />
        </DefaultButton>
      </div>
    </StyledContainer>
  );
};
