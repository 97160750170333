import React from 'react';
import styled from 'styled-components';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { CommandBar } from 'office-ui-fabric-react';

const StyledContainer = styled.div`
  width: 220px;
  .ms-Nav-navItem {
    .ms-Nav-compositeLink.is-expanded.is-selected {
      a {
        &:after {
          border-left: none;
        }
        background: #d83b0119;
        color: #d83b01;
        font-weight: 500;
      }
    }
    .ms-Nav-compositeLink {
      a {
        padding-left: 11px;
      }
    }
  }
  background: #fafafa;
  .ms-CommandBar {
    background: #fafafa;
    height: 48px;
    padding: 0;
    .ms-Button--commandBar {
      background: transparent;
      min-width: 48px;
      &:hover {
        background: #f1f1f1;
      }
      color: #d83b01;
    }
  }
`;

export default () => {
  const getItems = () => {
    return [
      {
        key: 'newItem',
        iconProps: {
          iconName: 'GlobalNavButton',
        },
      },
      {
        key: 'upload',
        iconProps: {
          iconName: 'Add',
        },
        name: 'New event',
      },
    ];
  };
  return (
    <StyledContainer>
      <CommandBar items={getItems()} style={{ backgroundColor: 'transparent' }} />
      <Nav
        onLinkClick={() => null}
        selectedKey="key3"
        selectedAriaLabel="Selected"
        ariaLabel="Nav basic example"
        styles={{
          root: {
            width: 220,
            height: window.innerHeight - 96,
            boxSizing: 'border-box',
            backgroundColor: '#fafafa',
            overflowY: 'auto',
          },
        }}
        groups={[
          {
            links: [
              {
                name: 'Home',
                url: '#',
                icon: 'ViewDashboard',
                expandAriaLabel: 'Expand Home section',
                collapseAriaLabel: 'Collapse Home section',
              },
              {
                name: 'Documents',
                url: 'http://example.com',
                key: 'key3',
                icon: 'GoToDashboard',
                isExpanded: true,
                target: '_blank',
              },
              {
                name: 'Pages',
                url: 'http://msn.com',
                key: 'key4',
                icon: 'WebAppBuilderModule',
                target: '_blank',
              },
              {
                name: 'Notebook',
                icon: 'Tennis',
                url: 'http://msn.com',
                key: 'key5',
              },
              {
                name: 'Communication and Media',
                url: 'http://msn.com',
                key: 'key6',
                icon: 'Heart',
                target: '_blank',
              },
              {
                name: 'News',
                icon: 'PlaybackRate1x',
                url: 'http://cnn.com',
                key: 'key7',
                target: '_blank',
              },
            ],
          },
        ]}
      />
    </StyledContainer>
  );
};
