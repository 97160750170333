import React from 'react';
import ReactDOM from 'react-dom';
import { Fabric, loadTheme, createTheme, mergeStyles } from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

const defaultTheme = {
  defaultFontStyle: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  palette: {
    themePrimary: '#d83b01',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#ffab7a',
    themeSecondary: '#f2844e',
    themeDarkAlt: '#e65f25',
    themeDark: '#b32a00',
    themeDarker: '#8c1c00',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff',
  },
};

loadTheme(createTheme(defaultTheme));

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html)': {
      margin: 0,
      padding: 0,
      minHeight: '100vh',
    },
  },
});

ReactDOM.render(
  <Fabric>
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <App />
    </ThemeProvider>
  </Fabric>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
